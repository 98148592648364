<template>
	<a href="#" @click.prevent="() => onLike()">
		<i class="icon icon-thumbsup" :class="{ 'text-success': likes.userlike }"></i>
	</a>
	{{ likes.rating }}
	<a href="#" @click.prevent="() => onDislike()">
		<i class="icon icon-thumbsdown" :class="{ 'text-danger': likes.userdislike }"></i>
	</a>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from 'vue';
import { like, dislike, calcLike } from './api';

export default defineComponent({
	props: {
		owner: {
			type: String,
			default: null
		}
	},

	setup(props: any) {
		const { owner } = props;

		const likes = reactive({
			userlike: 0,
			userdislike: 0,
			rating: 0
		});

		const onLike = () => owner && like(owner).then((response: any) => Object.assign(likes, response));

		const onDislike = () => owner && dislike(owner).then((response: any) => Object.assign(likes, response));

		onMounted(() => {
			if (owner) calcLike(owner).then((response: any) => Object.assign(likes, response));
		})

		return {
			likes,
			onLike,
			onDislike
		}
	}
})
</script>

<style></style>