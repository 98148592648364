<template>
	<div class="lds-container" :class="{ showloader: load }">
		<div class="lds-dual-ring"></div>
	</div>

	<component :is="layout">
		<router-view v-slot="{ Component }">
			<component :is="Component" v-if="loaded" />
		</router-view>
	</component>

	<TimeOut v-if="timeout"></TimeOut>
	<Modals></Modals>
	<Teleports></Teleports>
	<ContextMenu></ContextMenu>
</template>

<script lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import bus from '@/core/bus';
import Modals from "@/core/layouts/Modals/index.vue";
import TimeOut from "@/core/layouts/TimeOut/index.vue";
import ws_agent from '@/core/ws_agent';
import Teleports from "@/core/layouts/Teleports/index.vue";
import ContextMenu from "@/core/components/ContextMenu/index.vue";

import { list } from '@/core/api/equipments';
import { schema } from '@/core/components/DB/api';
import { constants } from '@/core/api/system';
import { deepMerge } from '@/core/helpers/utils';
import { loadSchema } from '@/core/db';

import EmptyLayout from "@/layouts/Empty.vue";
import DefaultLayout from "@/layouts/Default.vue";

import tables from '@/tables';
import enums from '@/enums';

export default {
	components: {
		DefaultLayout,
		EmptyLayout,
		TimeOut,
		Modals,
		Teleports,
		ContextMenu
	},

	setup() {
		const loaded = ref(false);
		const route = useRoute();
		const { state, commit } = useStore();

		const handeInterval = setInterval(() => {
			if (state?.user?.exp && state.timeout == 0) {
				const date = new Date;

				const timeout = ~~((state.user.exp - date.getTime()) / 1000);

				if (timeout < 180) commit('timeout', timeout);
			}
		}, 5000);

		const fetchSchema = async () => {
			const resSchema: any = await schema();

			loadSchema(deepMerge(resSchema, { tables, enums }));

			const resConstants: any = await constants();
			commit('constants', resConstants);

			const resEquipment: any = await list();
			commit('equipment', resEquipment);
			if (resEquipment.length > 0) ws_agent.open();
		}

		onMounted(async () => {
			await fetchSchema();

			loaded.value = true;

			bus.$on('login', fetchSchema);
			bus.$on('logout', fetchSchema);
		})

		onUnmounted(() => {
			clearTimeout(handeInterval);

			bus.$off('login', fetchSchema);
			bus.$off('logout', fetchSchema);
		});

		return {
			loaded,
			load: computed(() => state.load),
			layout: computed(() => route.meta.layout || "default-layout"),
			timeout: computed(() => state.timeout)
		}
	}
}
</script>

<style scoped>
.lds-container {
	display: flex;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	z-index: 99999 !important;
	background-color: rgba(0, 0, 0, 0.1);

	visibility: hidden;
	opacity: 0;
	transition: 0.1s;
}

.lds-container.showloader {
	visibility: visible;
	opacity: 1;
}

.lds-dual-ring {
	display: inline-block;
	width: 80px;
	height: 80px;
}

.lds-dual-ring:after {
	content: " ";
	display: block;
	width: 64px;
	height: 64px;
	margin: 8px;
	border-radius: 50%;
	border: 6px solid #337ab7;
	border-color: #337ab7 transparent #337ab7 transparent;
	animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
</style>
