import access from "./access";
import accounts from "./accounts";
import address_districts from "./address_districts";
import address_locality from "./address_locality";
import address_regions from "./address_regions";
import address_street from "./address_street";
import address_village_councils from "./address_village_councils";
import autos from "./autos";
import banks from "./banks";
import binding_regions from "./binding_regions";
import brigades from "./brigades";
import brigades_list from "./brigades_list";
import call_log from "./call_log";
import call_log_templates from "./call_log_templates";
import call_place from "./call_place";
import connected_equipment from "./connected_equipment";
import contacts from "./contacts";
import contacts_types from "./contacts_types";
import contragents from "./contragents";
import contragents_departments from "./contragents_departments";
import country from "./country";
import divisions_mvd from "./divisions_mvd";
import document_types from "./document_types";
import employees from "./employees";
import expertise from "./expertise";
import files from "./files";
import files_versions from "./files_versions";
import form_stat from "./form_stat";
import form1 from "./form1";
import health_statement from "./health_statement";
import history_changes from "./history_changes";
import icd10 from "./icd10";
import injections from "./injections";
import mailing from "./mailing";
import medicaments from "./medicaments";
import messages from "./messages";
import med_temp from "./med_temp";
import menu from "./menu";
import nomenclature from "./nomenclature";
import numerators from "./numerators";
import often_used from "./often_used";
import orders from "./orders";
import patients from "./patients";
import positions from "./positions";
import protocol_onmk from "./protocol_onmk";
import protocol_slr from "./protocol_slr";
import psych_cares from "./psych_cares";
import publications from "./publications";
import reasons from "./reasons";
import release_form from "./release_form";
import resuscitations from "./resuscitations";
import roles from "./roles";
import subscribers from "./subscribers";
import templates from "./templates";
import templates_roles from "./templates_roles";
import units from "./units";
import users from "./users";
import users_roles from "./users_roles";

export default {
	access,
	accounts,
	address_districts,
	address_locality,
	address_regions,
	address_street,
	address_village_councils,
	autos,
	banks,
	binding_regions,
	brigades,
	brigades_list,
	call_log,
	call_log_templates,
	call_place,
	connected_equipment,
	contacts,
	contacts_types,
	contragents,
	'contragents.departments': contragents_departments,
	country,
	divisions_mvd,
	document_types,
	employees,
	expertise,
	files,
	'files.versions': files_versions,
	form_stat,
	form1,
	health_statement,
	history_changes,
	icd10,
	injections,
	mailing,
	medicaments,
	menu,
	med_temp,
	messages,
	nomenclature,
	numerators,
	often_used,
	orders,
	patients,
	positions,
	protocol_onmk,
	protocol_slr,
	psych_cares,
	publications,
	reasons,
	release_form,
	resuscitations,
	roles,
	subscribers,
	templates,
	templates_roles,
	units,
	users,
	'users.roles': users_roles
}
