import { computed } from "vue";
import { useStore } from "vuex";

// import ws_agent from "@/core/ws_agent";
// import { availabeRole } from "@/core/helpers/access";

import stateStore from '@/core/store/index'

export const availableScanner = computed(() => stateStore.state.equipment.some((el: any) => el.type == 2 && el.active));
export const availableWebCam = computed(() => stateStore.state.equipment.some((el: any) => el.type == 3 && el.active));
export const availableCheckPrinter = computed(() => stateStore.state.equipment.some((el: any) => el.type == 4 && el.active));

export function printCheck(body: string) {
	// for (const equipment of stateStore.state.equipment) {
	// 	if (equipment.type == 4 && equipment.settings.length > 0 && (equipment.active || availabeRole(['admin']))) {

	// 		ws_agent.send({
	// 			'print-check': {
	// 				'name-printer': equipment.settings[0],
	// 				body
	// 			}
	// 		});

	// 		break;
	// 	}
	// }
}

export function printBarcodes(data: any) {
	const { width = '0 mm', height = '0 mm', gapM = '0 mm', gapN = '0 mm', direction = 1, barcodes = [] } = data;

	const document: any = [];

	document.push(`SIZE ${width}, ${height}`);
	document.push(`GAP ${gapM}, ${gapN}`);
	document.push(`DIRECTION ${direction}`);

	for (const barcode of barcodes) {
		document.push(`CLS`);
		document.push(`BARCODE 20, 20, "EAN13", 90, 2, 0, 2, 2, "${barcode.substr(0, 12)}"`);
		document.push(`PRINT 1,1`);
	}

	printCheck(document.join('\n') + '\n');
}
