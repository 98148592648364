<template>
	<textarea :id="selector"></textarea>

	<teleport to="#imageselect" v-if="images.length > 0">
		<template v-for="image in images" :key="image.id">
			<a href="#" @click.prevent="() => select(image)">
				<img :src="image.location" class="thumbnail mr-1" />
			</a>
		</template>
	</teleport>
</template>

<script>

import { onMounted, onUnmounted, ref } from 'vue';

import { genGUID } from '@/core/helpers/utils';

import { imagesRead } from '@/core/api/images';

import tinymce from 'tinymce';

import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/models/dom/model.min.js';

import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/code';
import 'tinymce/plugins/link';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/image';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/quickbars';

import '@/core/components/DB/DBEditor/langs/ru';

export default {
	props: {
		content: {
			type: String,
			default: ''
		},
		owner: {
			type: String,
			default: null
		},
		options: {
			type: Object,
			default: () => ({})
		}
	},

	setup(props) {
		const images = ref([]);

		let editor = null;

		let callback = null;

		const selector = `editor_${genGUID()}`;

		const select = (image) => {
			if (callback) {
				callback(image.location, { alt: image.description });

				editor.windowManager.close();
			}
		}

		const file_picker_callback = (_callback, value, meta) => {
			if (meta.filetype == 'image') {
				editor.windowManager.open({
					size: 'large',
					title: 'Выбор изображения',
					body: {
						type: 'panel',
						items: [
							{
								type: 'htmlpanel',
								html: `<div id="imageselect"></div>`
							}
						]
					},
					buttons: [
						{
							type: 'cancel',
							text: 'Закрыть'
						}
					],
					onClose: () => {
						images.value.splice(0, images.value.length);

						callback = null;
					}
				});

				imagesRead(props.owner).then(response => {
					images.value = response;

					callback = _callback;
				})
			}
		}

		onMounted(async () => {
			await tinymce.init(Object.assign({
				selector: `#${selector}`,
				language: 'ru',
				menubar: false,
				plugins: [
					'fullscreen',
					'advlist',
					'lists',
					'table ',
					'code ',
					'image',
					'link',
					'preview',
					'quickbars'
				],
				file_picker_types: 'image',
				file_picker_callback,
				height: 500,
				images_upload_handler: (blobInfo) => new Promise((resolve) => resolve(URL.createObjectURL(blobInfo.blob()))),
				init_instance_callback: (editor) => editor.setContent(props.content ? props.content : '', { format: 'html' }),
				noneditable_noneditable_class: 'mceNonEditable',

				// toolbar: 'undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent | table numlist bullist hr| forecolor backcolor removeformat | fullscreen code | image link',
				// imagetools_cors_hosts: ['picsum.photos'],
				// autosave_ask_before_unload: true,
				// image_advtab: true,
				// importcss_append: true,
				// typeahead_urls: false,
				// paste_data_images: true,
				// convert_urls: false,
				// image_caption: true,
				// // quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
				// toolbar_mode: 'sliding',
				// contextmenu: 'link image imagetools table',
				// content_style: 'body { font-family: Helvetica,Arial,sans-serif; font-size: 14px; background-color: #ffffff; margin: 5px;}',
				// content_css: '/assets/css/bootstrap.min.css',
			}, props.options));

			editor = tinymce.get(selector);
		})

		onUnmounted(() => {
			if (editor) editor.destroy();
		})

		return {
			selector,
			images,
			select
		}
	}
}
</script>

<style scoped>
.thumbnail {
	padding: 0.25rem;
	background-color: #f5f5f5;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.1875rem;
	box-shadow: 0 1px 2px rgb(0 0 0 / 8%);
	max-width: 100%;
	width: 100px;
	height: auto;
}
</style>
