import { defineAsyncComponent } from 'vue';

export default {
	forms: {
		list: {
			config: {
				sorting: true,
				fields: {
					parent: {
						config: {
							visible: false
						}
					},
					name: {
						config: {
							sorting: 'publications.name'
						}
					},
					author: {},
					message: {
						config: {
							visible: false
						}
					},
					created_at: {}
				}
			},
			component: defineAsyncComponent(() => import('./forms/List.vue'))
		},
		edit: {
			panel: {
				width: '70%'
			},
			config: {
				fields: {
					author: {},
					name: {},
					message: {},
					files: {
						fields: {
							parent: {},
							name: {},
							storever: {},
							size: {},
							description: {},
							author: {},
							created_at: {}
						}
					}
				}
			},
			component: defineAsyncComponent(() => import('./forms/Edit.vue'))
		},
		editgroup: {
			panel: {
				width: '80%'
			},
			component: defineAsyncComponent(() => import('./forms/EditGroup.vue'))
		},
		selectgroup: {
			panel: {
				height: '500px',
				width: '500px'
			},
			config: {
				fields: {
					login: {
					},
					name: {
					}
				}
			},
			component: defineAsyncComponent(() => import('./forms/Select.vue'))
		}
	}
}