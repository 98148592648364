import dayjs from 'dayjs';
import { reactive, ref, watch } from 'vue';

import Bus from '@/core/bus';
import Store from "@/core/store";
import DBStore from '@/core/db_store';
import { getModel, newRec } from '@/core/db';
import { toastError } from "@/core/helpers/toastify";
import { b64toBlob, genGUID, deepMerge } from '@/core/helpers/utils';
import { ResultResponse, query, saveForm } from '@/core/components/DB/api';

const md5 = require('md5');

export default class DBStoreRecord extends Store {
	public watchFields: any = {};
	public data = reactive<any>({});

	//Признак что новая запись стора
	public isNew = ref<boolean>(true);

	private hash = '';

	private observe = true;

	constructor(public name: string, config: any = {}) {
		super();

		const model: any = getModel(name);

		this.model = deepMerge({}, model ? model : {}, config);

		this.model.offLine = !!(model?.offLine || model?.subtable);

		this.createState(config);

		Bus.$on('save-data', (saveData: any) => {
			if (config.info && saveData.table == this.name && saveData.data.id == this.data.id) {
				this.loadData(saveData.data);
			}

			for (const key in this.state.fields) {
				const field = this.state.fields[key];

				if (typeof field.type == 'object' && field.type.reference && saveData.table == field.type.reference) {
					const schema = getModel(saveData.table);

					if (schema && this.data[key] == saveData.data[schema.key]) {
						this.data[`_${key}`] = saveData.data[schema.notion];
					}
				}
			}
		});
	}

	async fetchData(id: string, options: any = {}) {
		if (!(this.model.subtable || this.model.offLine)) {
			const config: any = {
				table: this.name,
				method: 'object',
				data: { id },
				params: Object.assign({ fields: this.getFetchFields() }, options)
			};

			if (this.owner) config['owner'] = this.owner;

			try {
				const response: ResultResponse = await query(config);

				if (response.complete && response.data) {
					this.loadData(response.data);

					this.isNew.value = false;
				}

				// setTimeout(() => this.createHash(), 0);
			} catch (error) {
				console.error(error);
			}

			return this.data;
		}
	}

	async copyData(id: string, defaults = {}) {
		if (!this.model.subtable && !this.model.offLine) {
			const config: any = {
				table: this.name,
				method: 'copy',
				data: { id },
				defaults
			};

			if (this.owner) config['owner'] = this.owner;

			try {
				const response: ResultResponse = await query(config);

				if (response.complete) this.loadData(response.data);
			} catch (error) {
				console.error(error);
			}

			return this.data;
		}
	}

	setWatching() {
		const watching = (data: any, fields: any) => {
			for (const key in fields) {
				const field = fields[key];

				if (data) {
					if (typeof field.type == 'string' && field.type == 'JSON') {
						if (!(key in data)) data[key] = {};

						if (field?.config?.watch) {
							this.watchFields[key] = watch(
								() => data[key],
								(newValue, oldValue) => this.observe && field.config.watch(newValue, oldValue, this),
								{ deep: true }
							)
						}

						if (field?.model?.fields) watching(data[key], field.model.fields);
					}

					if (field?.config?.watch) {
						if (field?.type?.table) {
							if (!(key in data)) data[key] = [];

							this.watchFields[key] = watch(
								() => data[key],
								(newValue, oldValue) => this.observe && field.config.watch(newValue, oldValue, this),
								{ deep: true }
							)
						} else {
							// if (!(key in data)) data[key] = null;

							this.watchFields[key] = watch(
								() => data[key],
								(newValue, oldValue) => this.observe && field.config.watch(newValue, oldValue, this)
							)
						}
					}
				}
			}
		}

		watching(this.data, this.state.fields);
	}

	stopWatching() {
		Object.keys(this.watchFields).forEach((el: any) => {
			this.watchFields[el]();

			delete this.watchFields[el];
		});
	}

	createDBStore(name: string) {
		const field = this.state.fields[name];

		const store = new DBStore(field.table);
		store.model = field.type.table;
		store.files = this.files;
		store.model.subtable = true;

		store.createState();

		if (field.depends) {
			store.owner = this.data[field.depends];
			store.model.depends = field.depends;
		}

		if (!this.data[name]) this.data[name] = [];

		store.data.rows = this.data[name];

		store.data.position = store.data.rows.length > 0 ? 0 : -1;

		return store;
	}

	clearData() {
		for (const key in this.model.fields) {
			if (this.data[key]) delete this.data[key];
		}
	}

	createHash() {
		return this.hash = md5(JSON.stringify(this.data));
	}

	get changed() {
		return this.hash != md5(JSON.stringify(this.data));
	}

	getDataStore(fields: any, data: any): any {
		const field: any = fields.shift();

		if (fields.length > 0) {
			if (!data[field]) data[field] = {};

			return this.getDataStore(fields, data[field]);
		} else {
			return data;
		}
	}

	setValue(nameField: string, value: any) {
		const fields = nameField.split('.');

		const field = fields[fields.length - 1];

		const data = this.getDataStore(fields, this.data);

		const fieldModel = this.getModel(nameField);

		if (typeof fieldModel.type == 'object') {
			data[field] = value;

			if (value) {
				if (fieldModel.type.enum) {
					const enumData = typeof fieldModel.type.enum == 'string' ? this.getEnum(this.data, fieldModel) : fieldModel.type.enum;

					const _enum = enumData.find((el: any) => el.id == value);

					if (_enum) data[`_${field}`] = _enum.name;
				}
			} else if (data[`_${field}`]) {
				delete data[`_${field}`];
			}
		} if (typeof fieldModel.type == 'string') {
			switch (fieldModel.type) {
				case 'JSON': {
					if (!data[field]) data[field] = {};

					if (fieldModel.model) this.setData(data[field], value, fieldModel.model);

					return;
				}

				default:
					data[field] = value;
			}
		}

		return data[field];
	}

	getValue(nameField: string, notion = true) {
		const fields = nameField.split('.');

		const field = fields[fields.length - 1];

		const data = this.getDataStore(fields, this.data);

		const fieldModel = this.getModel(nameField);
		if (fieldModel) {
			if (fieldModel?.config?.calc) {
				return typeof fieldModel.config.calc == 'function' ? fieldModel.config.calc(this.data, this) : fieldModel.config.calc;
			} else {
				if (typeof fieldModel.type == 'object') {
					return data[field];
				} if (typeof fieldModel.type == 'string') {
					switch (fieldModel.type) {
						case 'JSON': {
							if (fieldModel.model) {
								const jsonData = data[field] ? data[field] : {};

								return notion && fieldModel?.config?.notion && typeof fieldModel.config.notion == 'function' ? fieldModel.config.notion(jsonData) : jsonData;
							}
						}
							break;
					}

					return data[field];
				}
			}
		}

		return null;
	}

	/**
	 * Получение предсьавления поля, возможно нужно убрать, нигде не используется
	 * @param nameField 
	 * @param notion 
	 * @returns 
	 */
	getNotion(nameField: string, notion = true) {
		const fieldModel = this.getModel(nameField);

		if (fieldModel?.config?.calc) {
			return typeof fieldModel.config.calc == 'function' ? fieldModel.config.calc(this.data, this) : fieldModel.config.calc;

		} else {
			const fields = nameField.split('.');

			const field = fields[fields.length - 1];

			const data = this.getDataStore(fields, this.data);

			const value = data[field];

			if (!value) return null;

			if (typeof fieldModel.type == 'object') {
				if (fieldModel.type.enum) {
					const enumData = typeof fieldModel.type.enum == 'string' ? this.getEnum(this.data, fieldModel) : fieldModel.type.enum;
					const _enum = enumData.find((el: any) => el.id == value);
					if (_enum) return _enum.name;
				}

				if (data[`_${field}`]) return data[`_${field}`];

				return value;

			} if (typeof fieldModel.type == 'string') {
				switch (fieldModel.type) {
					case 'JSON': {
						if (fieldModel.model) {
							const jsonData = data[field] ? data[field] : {};

							return notion && fieldModel?.config?.notion && typeof fieldModel.config.notion == 'function' ? fieldModel.config.notion(jsonData) : jsonData;
						}
					}
						break;

					case 'DATE':
						return dayjs(value).format("DD.MM.YYYY HH:mm:ss")

					case 'DATEONLY':
						return dayjs(value).format("DD.MM.YYYY")

					case 'TIME':
						return dayjs(`0000-00-00 ${value}`).format("HH:mm:ss");
				}

				return data[field];
			}
		}
	}

	fieldsTrim(fields: any) {
		for (const field of fields) {
			if (this.model.fields[field]) {
				const model = this.model.fields[field];

				if (typeof model.type == 'string') {
					if (model.type == 'STRING' || model.type == 'TEXT') {
						if (typeof this.data[field] == 'string') {
							this.data[field] = this.data[field].trim();
						}
					}
				}
			}
		}
	}

	async newRecord(config: any = {}, data: any = {}) {
		const response = await this.defaultsData(data);

		return newRec(this.name, response, null, config);
	}

	//Проверить
	async editRecord(config: any, id: any) {
		return newRec(this.name, id, null, config);
	}

	defaultsData = async (data: any = {}) => {
		this.observe = false;

		if (this.model.subtable || this.model.offLine) {
			this.loadData(data);
		} else {
			const response: ResultResponse = await query({ table: this.name, method: 'new', data });

			if (response.complete) {
				this.loadData(response.data);

			}
		}

		//Костыль для убирания owner полей в табличных частях
		const procData = (data: any, model: any) => {
			if (model.key && data[model.key]) {
				delete data[model.key];
				if (model.ownerField && model.ownerField in data) delete data[model.ownerField];
			}

			for (const key in model.fields) {
				const field = model.fields[key];

				if (typeof field.type == 'object') {
					if (field.type.table) {
						const tableModel = field.type.table;

						if (data[key]) {
							for (const row of data[key]) procData(row, tableModel);
						}
					}
				}
			}
		}

		procData(this.data, this.model);

		setTimeout(() => { this.observe = true }, 0);

		return this.data;
	}

	loadData(data: any, copy = false) {
		if (this.model) {
			this.observe = false;

			this.setData(this.data, data, this.state, this.owner, copy);

			setTimeout(() => {
				this.state.loaded = true;
				this.observe = true;
			}, 0);
		}
	}

	setData(data: any, newData: any, model: any, owner: any = null, copy = false) {
		if (copy) {
			delete newData[model.key];
			delete newData[model.ownerField];
		}

		if (model.key && !data[model.key]) {
			data[model.key] = genGUID();
		}

		for (const key in newData) {
			if (key == '$file') {
				this.files[newData[model.key]] = b64toBlob(newData[key], newData.type);
			}
		}

		for (const key in model.fields) {
			const field = model.fields[key];

			const calc = field?.config?.calc;

			if (calc) data[key] = typeof calc == 'function' ? calc(newData) : calc;

			//Загрузка объектных полей
			if (typeof field.type == 'object') {

				if (field.type.reference) {
					if (key in newData) {
						data[key] = newData[key];
						data[`_${key}`] = newData[`_${key}`];
					}

					//Перечисление
				} else if (field.type.enum) {
					if (key in newData) {
						data[key] = newData[key];

						if (newData[`_${key}`]) {
							data[`_${key}`] = newData[`_${key}`];
						} else {
							const enumData = typeof field.type.enum == 'string' ? this.getEnum(this.data, field) : field.type.enum;

							const _enum = enumData.find((el: any) => el.id == newData[key]);

							if (_enum) data[`_${key}`] = _enum.name;
						}
					}
				} else if (field.type.fields) {
					if (key in newData) data[key] = newData[key];
				} else if (field.type.sql) {
					if (key in newData) data[key] = newData[key];
				}
			} else {
				//Примитивное поле
				if (key in newData) {
					if (field.type == 'DATE') {
						data[key] = newData[key] ? dayjs(newData[key]).format("YYYY-MM-DDTHH:mm:ss") : null;
					} else if (field.type == 'JSON') {
						if (field.model) {
							if (!data[key]) data[key] = {};

							this.setData(data[key], newData[key], field.model);
						} else {
							data[key] = newData[key];
						}
					} else {
						data[key] = newData[key];
					}
				}
			}
		}

		if (owner && model.depends && model.ownerField) {
			data[model.ownerField] = owner;
		}

		//Загрузка табличных частей
		for (const key in model.fields) {
			const field = model.fields[key];

			if (typeof field.type == 'object') {
				if (field.type.table) {
					const tableModel = field.type.table;

					if (field.depends) tableModel.depends = field.depends;

					if (tableModel.ownerField && tableModel.depends) {
						const owner = data[tableModel.depends];

						if (newData[key]) {
							if (data[key]) {
								data[key].splice(0, data[key].length);
							} else {
								data[key] = [];
							}

							for (const newRow of newData[key]) {
								const dataA = {};

								this.setData(dataA, newRow, tableModel, owner, copy);

								data[key].push(dataA);
							}
						} else {
							if (!data[key]) data[key] = [];
						}
					}
				}
			}
		}
	}

	async save(saveParams = {}): Promise<any> {
		if (await this.model.onBeforeSave(this.data, this.isNew.value)) {
			// //Если в данных есть ключевое поле но оно пустое(null), то удаляем его
			if (this.model.key in this.data && !this.data[this.model.key]) delete this.data[this.model.key];

			if (this.model.subtable || this.model.offLine) {
				Bus.$emit('save-data', { table: this.name, data: this.data, newrecord: this.isNew.value });

				this.model.onAfterSave(this.data);

				return this.data;
			} else {
				const params = {
					...saveParams,
					isNew: this.isNew.value
				};

				const formSave = async () => {
					if (Object.keys(this.files).length > 0) {
						const formData = new FormData();

						formData.append('table', this.name);
						formData.append('params', JSON.stringify(params));
						formData.append('data', JSON.stringify(this.getData(this.data)));

						for (const key in this.files) {
							formData.append(key, this.files[key]);
						}

						const result: ResultResponse = await saveForm(formData);

						return result;
					} else {
						const result: ResultResponse = await query({
							table: this.name,
							method: 'save',
							params,
							data: this.getData(this.data)
						});

						return result;
					}
				}

				try {
					const result: ResultResponse = await formSave();

					if (result.complete) {
						this.loadData(result.data);

						this.model.onAfterSave(result.data);

						Bus.$emit('save-data', { table: this.name, data: result.data, newrecord: this.isNew.value });

						for (const member in this.files) delete this.files[member];

						this.isNew.value = false;

						this.createHash();

						return this.data;
					} else {
						toastError(result.message);
					}
				} catch (error) {
					console.error(error);
				}
			}
		}

		return null;
	}

	clearField = (nameField: string) => {
		const fieldModel = this.getModel(nameField);

		if (fieldModel) {
			if (typeof fieldModel.type == 'object') {
				if (fieldModel.type.reference) {
					this.setValue(nameField, null);
				} else if (fieldModel.type.enum) {
					this.setValue(nameField, null);
				} else if (fieldModel.type.table) {
					//Подправить с учтетом JSON полей
					if (this.data[nameField].length > 0) {
						//Очистка файлов, если совпадает ID
						for (const row of this.data[nameField]) {
							if (this.files[row[fieldModel.type.table.key]]) delete this.files[row[fieldModel.type.table.key]];
						}

						this.data[nameField].splice(0, this.data[nameField].length);
					}
				}
			} else {
				this.setValue(nameField, null);
			}

			this.clearDepends(nameField);
		}
	}

	clearDepends = (nameField: string) => {
		const clear = (fields: any, field: string) => {
			for (const key in fields) {
				const _field: any = fields[key];

				if (_field.type == 'JSON') {
					if (_field?.model?.fields)
						clear(_field.model.fields, key);
				} else {
					if (_field?.depends == nameField) {
						const res = [];

						if (field) res.push(field);

						res.push(key);

						this.clearField(res.join('.'));
					}
				}
			}
		}

		clear(this.state.fields, '');
	}
}