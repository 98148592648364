<template>
	<video ref="videoRef" autoplay playsinline webkit-playsinline muted></video>
	<canvas style="display: none;"></canvas>
</template>

<script>
//Шпаргалка по работе с медиа в браузере
//https://habr.com/ru/companies/timeweb/articles/667148/

import { onMounted, ref } from 'vue'
import { b64toBlob } from '@/core/helpers/utils';

export default {
	inheritAttrs: false,

	props: {
		panel: {
			type: Object,
			default: () => ({})
		}
	},

	setup() {
		let videoStream = null;

		const videoRef = ref(null);

		onMounted(() => {
			navigator.mediaDevices
				.getUserMedia(
					{
						video: {
							facingMode: "environment",
							width: {
								min: 1280,
								ideal: 1280,
								max: 1920,
							},
							height: {
								min: 720,
								ideal: 720,
								max: 1080
							}
						},
						audio: false
					}
				)
				.then(stream => {
					videoStream = stream;

					videoRef.value.srcObject = stream;

					return navigator.mediaDevices.enumerateDevices();
				})
				.catch(error => {
					console.error(error);
				});
		})

		const takeSnapshot = () => {
			const
				hidden_canvas = document.querySelector('canvas'),
				context = hidden_canvas.getContext('2d'),
				width = videoRef.value.videoWidth,
				height = videoRef.value.videoHeight;

			hidden_canvas.width = width;
			hidden_canvas.height = height;

			context.drawImage(videoRef.value, 0, 0, width, height);

			const data = hidden_canvas.toDataURL("image/jpeg", 0.75);

			const dataUrl = data.split(',');
			const base64 = dataUrl[1];
			const type = dataUrl[0].match(/:(.*?);/)[1];

			return new File([b64toBlob(base64)], `screenshot.jpg`, { type });
		}

		const closeCamera = () => videoStream?.getTracks().forEach((track) => track.stop())

		return {
			videoRef,
			takeSnapshot,
			closeCamera
		}
	}
}
</script>

<style>
video {
	width: 100%;
	background: rgba(0, 0, 0, 0.2);
}
</style>