import { defineAsyncComponent } from 'vue';

export default {
	forms: {
		edit: {
			config: {
				fields: {
					login: {
					},
					name: {
					},
					email: {
					},
					roles: {
						fields: {
							owner: {
								config: {
									visible: false
								}
							},
							role: {}
						}
					},
					files: {
						fields: {
							// owner: {},
							name: {},
							storever: {},
							size: {},
							description: {},
							author: {},
							created_at: {}
						}
					}
				}
			},
			panel: {
				// height: '200px',
				width: '80%'
			},
			component: defineAsyncComponent(() => import('./Edit/Index.vue'))
		},
		editgroup: {
			panel: {
				width: '80%'
			},
			component: defineAsyncComponent(() => import('./Edit/EditGroup.vue'))
		},
		list: {
			component: defineAsyncComponent(() => import('./List/Index.vue'))
		},
		select: {
			panel: {
				height: '500px',
				width: '70%'
			},
			config: {
				fields: {
					login: {},
					name: {}
				}
			},
			component: defineAsyncComponent(() => import('./Select/Index.vue'))
		},
		selectgroup: {
			panel: {
				height: '500px',
				width: '70%'
			},
			config: {
				fields: {
					login: {},
					name: {}
				}
			},
			component: defineAsyncComponent(() => import('./SelectGroup/Index.vue'))
		}
	}
}