import { h } from "vue";
import DBStore from "@/core/db_store";
import { getModel } from "@/core/db";

import { openPanel } from "./layouts";
import DBStoreRecord from "./db_store_record";
import { isAsync } from "./helpers/utils";

export default class ListController {
	public store: DBStore;
	public config: any;
	public filter: any = null;
	public filterModel: any = {};
	public fetching: boolean = false;

	constructor(public name: any, public params: any = {}, public grid: any = null) {
		this.settings();

		this.store = new DBStore(name, this.config);
	}

	settings() {
		const model: any = getModel(this.name);

		this.config = {
			filters: () => this.filters(),
			default: async () => isAsync(this.defaults) ? await this.defaults() : this.defaults(),
			...model?.forms?.list?.config ? model.forms.list.config : {},
			...this.params
		}

		return this.config;
	}

	filters() {
		const result: any = [];

		return result;
	}

	defaults() {
		const result: any = {};

		return result;
	}

	edit(id: any) {
		this.store.fetchData({ id }).then((data) => {
			if (data.length && this.grid.value) this.grid.value.openRow({ id });
		});
	}

	async open(id: any) { }

	setButtons(buttons = {}) {
		this.createPanelFun([
			{
				edit: this.store.accessRead() ? {} : null,
				add: this.store.accessCreate() ? {} : null,
				delete: this.store.accessDelete() ? {} : null,
				...buttons
			}
		])
	}

	createPanelFun(items: any = []) {
		const grid = this.grid.value;

		grid.controller.createPanelFun(items);
	}

	fetchParams() {
		return {}
	}

	async fetchData(params = {}) {
		if (!this.fetching) {
			this.fetching = true;

			await this.grid.value.controller.fetchData({
				params,
				...this.fetchParams()
			});

			this.fetching = false;
		}
	}

	/**
	 * Модель фильтра
	 * @returns 
	 */
	modelFilter() {
		return {}
	}

	/**
	 * Представление фильтра
	 * @param data 
	 * @returns 
	 */
	notionFilter(data?: any) {
		const result: any[] = [];

		return result.join('; ');
	}

	editFilter(component: any) {
		openPanel({
			modal: true,
			width: '80%',
			caption: "Дополнительный отбор",
			onCreate: (panel: any) => {
				return {
					component: h(component, { data: this.filter.data.filters, fields: this.filterModel.model.fields }),
					buttons: [
						{
							filters: {
								class: "btn btn-action",
								caption: 'Установить',
								onClick: () => {
									this.filter.setValue('filters', panel.ref.store.data);

									panel.close();
								}
							}
						}
					]
				}
			}
		})
	}

	public createFilter(name: string, component: any = null) {
		this.filterModel = {
			description: 'Дополнительный отбор',
			type: 'JSON',
			config: {
				watch: () => this.fetchData(),
				triggers: (controller: any) => {
					const result: any = [];

					if (component) {
						result.push({
							text: <i class="icon icon-more-horizontal"></i>,
							title: 'Дополнительный отбор',
							onClick: () => this.editFilter(component)
						});

						result.push({
							text: <i class="icon icon-x"></i>,
							title: 'Очистить',
							onClick: () => Object.keys(controller.data.filters).forEach((key) => delete controller.data.filters[key])
						});
					}

					return result;
				},
				notion: (data: any) => this.notionFilter(data)
			},
			model: {
				fields: this.modelFilter()
			}
		}

		this.filter = new DBStoreRecord(
			name,
			{
				fields: {
					filters: this.filterModel
				}
			}
		);
	}

	public getSelected() {
		const result: any = [];

		if (this.store.data.select.length > 0) {
			this.store.data.select.forEach((el: any) => result.push(el));
		} else {
			if (this.store.currentData())
				result.push(this.store.currentData().id)
		}

		return result;
	}

	get currentData() {
		return this.grid.value.store.currentData();
	}

	setup() {
		return {
			store: this.store,
			config: this.config,
			filter: this.filter,
		}
	}
}