import dayjs from 'dayjs';
import tinymce from 'tinymce';

import stateStore from "@/core/store/index";

import { query, saveForm } from "@/core/components/DB/api";
import { genGUID, srcToBlob } from '@/core/helpers/utils';

import { config } from "@/config";

/**
 * Сохранение формы комментария
 * @param data - данные комментария
 * @param files - файлы
 * @returns 
 */
const saveComment = async (data: any, files: any) => {
	if (Object.keys(files).length > 0) {
		const formData = new FormData();
		formData.append('table', 'comments');
		formData.append('data', JSON.stringify(data));
		for (const key in files) {
			formData.append(key, files[key]);
		}

		const commentdata: any = await saveForm(formData);

		commentdata.data.likes = {
			userlike: false,
			userdislike: false,
			rating: 0,
			like: 0,
			dislike: 0
		}

		return commentdata.data;

	} else {
		const commentdata: any = await query({
			table: 'comments',
			method: 'save',
			data
		});

		commentdata.data.likes = {
			userlike: false,
			userdislike: false,
			rating: 0,
			like: 0,
			dislike: 0
		}

		return commentdata.data;
	}
}

/**
 * Сохранения комментария на сервер
 * @param data - Данные коментария
 * @returns 
 */
export async function sendDataComment(data: any) {
	const files: any = {};

	if (data.message) {
		let pos1: number = data.message.indexOf('src="blob:');

		while (pos1 != -1) {
			const pos2 = data.message.indexOf('"', pos1 + 5);
			if (pos2 != -1) {
				const substr: string = data.message.substring(pos1 + 5, pos2);

				const blob: any = await srcToBlob(substr);

				const file: any = {
					id: genGUID(),
					owner: data.id,
					name: 'image.jpg',
					size: blob.size,
					type: blob.type,
					lasteditor: stateStore.state.user['employee'],
					_lasteditor: stateStore.state.user['_employee'],
					updated_at: dayjs().format(),
					author: stateStore.state.user['employee'],
					_author: stateStore.state.user['_employee'],
					created_at: dayjs().format()
				}

				data['files'].push(file);

				data.message = data.message.replace(data.message.substring(pos1, pos2 + 1), `src="${config.api}/images/${file['id']}"`);

				files[file['id']] = blob;
			}

			pos1 = data.message.indexOf('src="blob:');
		}

		return await saveComment(data, files);
	}
}

/**
 * Сохранение комментария с редактора на сервер
 * @param owner - владелец комментария
 * @returns 
 */
export default async function sendComment(owner: string) {
	const editor: any = tinymce.activeEditor;

	const data: any = {
		id: genGUID(),
		owner,
		author: stateStore.state.user['employee'],
		message: editor.getContent({ format: 'html' }),
		files: []
	}

	return await sendDataComment(data);
}
