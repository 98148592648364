<template>
	<div class="card">
		<div class="card-header header-elements-inline" v-if="header">
			<h5 class="card-title">{{ header }}</h5>
		</div>
		<div class="card-body">
			<CommentsItems :comments="comments" :likes="likes" @send="onSend" @data="onData" :readonly="readonly" :timeAgo="timeAgo"></CommentsItems>

			<template v-if="!readonly">
				<div class="row mb-1 mt-2">
					<div class="col">
						<Editor :content="comment" :owner="owner" :options="options"></Editor>
					</div>
				</div>

				<div class="text-left">
					<button type="button" class="btn btn-action" @click="send">
						<i class="icon-plus22 mr-1"></i> Добавить комментарий
					</button>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import { ref } from 'vue';

import tinymce from 'tinymce';

import Editor from '../Editor';
import CommentsItems from "./CommentsItems";
import sendComment from './sendComment';

import { commentRead } from './api';

export default {
	emits: ['send', 'data', 'count'],

	props: {
		owner: {
			type: String,
			default: null
		},
		likes: {
			type: Boolean,
			default: false
		},
		readonly: {
			type: Boolean,
			default: false
		},
		timeAgo: {
			type: Boolean,
			default: true
		},
		header: {
			type: [String, Boolean],
			default: 'Комментарии'
		}
	},

	components: {
		CommentsItems, Editor
	},

	watch: {
		owner: {
			immediate: true,
			handler(newVal) {
				if (newVal) this.fetchData(newVal);
			}
		}
	},

	setup(props, { emit }) {
		const comments = ref([]);
		const comment = ref('');

		const options = {
			height: 200,
			toolbar: 'bold italic underline strikethrough | forecolor backcolor removeformat | image',
			quickbars_selection_toolbar: ''
		};

		const fetchData = (owner) => {
			commentRead(owner).then(response => {
				comments.value = response;

				emit("data", comments.value);

				emit("count", response.length);
			})
		}

		const send = async () => {
			const editor = tinymce.activeEditor;

			const response = await sendComment(props.owner);

			comment.value = "";

			editor.setContent(comment.value, { format: 'html' })

			comments.value.push(response);

			emit("data", comments.value);

			emit("count", comments.value.length);
		}

		const onSend = (data) => emit("send", data);

		const onData = (data) => {
			emit("data", data);

			emit("count", data.length);
		}

		return {
			comments,
			comment,
			options,
			send,
			fetchData,
			onSend,
			onData
		}
	}
}
</script>

<style></style>
