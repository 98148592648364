<template>
	<div class="navtable" v-if="settings.panelFun">
		<PanelFun :items="panelFun" />
	</div>

	<Tree
		ref="tree"
		:data="data"
		:deep="deep"
		:height="settings.height"
		:notion="settings.notion"
		@node:open="nodeOpen"
		@node:dblclick="dblclick"
		@node:delete="nodeDelete"
		@node:active="active"
		@node:contextmenu="contextMenu"
		@text:changed="textChanged"
		@node:dragging:finish="draggingFinish">
	</Tree>
</template>

<script>
import { defineComponent, onMounted } from 'vue'

import Bus from '@/core/bus'
import Tree from "@/core/components/Tree"

import DBTreeController from './controller';

export default defineComponent({
	inheritAttrs: false,

	components: {
		Tree
	},

	emits: ['active', 'select'],

	props: {
		table: {
			type: String,
			default: ''
		},
		store: {
			type: Object,
			default: () => ({})
		},
		config: {
			type: Object,
			default: () => ({})
		},
		deep: {
			type: Number,
			default: 255
		},
		folders: {
			type: Boolean,
			default: true
		},
		elements: {
			type: Boolean,
			default: true
		},
		selectmode: {
			type: Boolean,
			default: false
		},
		selectFolders: {
			type: Boolean,
			default: true
		},
		selectElements: {
			type: Boolean,
			default: true
		}
	},

	setup(props, { emit }) {
		const controller = new DBTreeController(props, emit);

		onMounted(async () => {
			const refTree = controller.tree.value;

			Bus.$on('save-data', saveData => {
				if (saveData.table == controller.table) {
					const node = refTree.findNode(saveData.data.id);
					if (node) node.setData(saveData.data)
				}
			});

			await controller.fetchData();
		});

		return {
			controller,
			tree: controller.tree,
			data: controller.data,
			panelFun: controller.panelFun,
			settings: controller.settings,
			dblclick: (node) => controller.dblclick(node),
			active: (node) => controller.active(node),
			contextMenu: (e, node) => controller.contextMenu(e, node),
			textChanged: (newText, prevText, active) => controller.textChanged(newText, prevText, active),
			draggingFinish: (dragElement, item, dragmode) => controller.draggingFinish(dragElement, item, dragmode),
			nodeDelete: (node) => controller.nodeDelete(node),
			nodeOpen: (node, treeController) => controller.nodeOpen(node, treeController)
		}
	}
})
</script>

<style scoped></style>
