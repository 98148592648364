import { Buffer } from 'buffer';
import TimeAgo from 'javascript-time-ago';
import ru from 'javascript-time-ago/locale/ru.json'
const md5 = require('md5');

TimeAgo.addDefaultLocale(ru);

const timeAgo = new TimeAgo('ru-RU');

export const timeago: any = (datatime: any) => timeAgo.format(new Date(datatime));

export const isVisible = (element: any) => element && (element.offsetWidth > 0 || element.offsetHeight > 0);

export function copyObject(data: any, fields: any = null, defaults: any = null): any {
	const result: any = {};

	if (fields) {
		for (const key of fields) {
			if (key in data) {
				result[key] = data[key];
			}
		}
	} else {
		Object.assign(result, data);
	}

	if (defaults) Object.assign(result, defaults);

	return result;
}

export function genGUID(): string {
	const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
	return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

/**
 * number -> hex
 * @param n 
 * @returns 
 */
export const dechex = (n: number): string => {
	const hex: string = n.toString(16);
	return hex.length == 1 ? '0' + hex : hex;
}

/**
 * hex -> number
 * @param hex 
 * @returns 
 */
export const hexdec = (hex: string): number => parseInt(hex, 16);

export function checkSnils(value: string): boolean {
	if (!value) return false;

	const snils: string = value.replace(/-/g, "");

	const res = Number(snils.substr(-2, 2));

	let sum = 0;

	for (let i = 0; i < 9; i++) {
		sum += Number(snils[i]) * (9 - i);
	}

	if (sum > 101) sum %= 101;

	if (sum == 101 || sum == 100) sum = 0;

	return sum == res;
}

export function checkEmail(email: string): boolean {
	return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
}

export const addArr: any = (array: any, item: any, field: any) => {
	const findElement = array.find((element: any) => element[field] == item[field]);
	if (findElement) return findElement;

	array.push(item);

	return item;
}

export function deepClone(obj: any): any {
	if (Array.isArray(obj)) {
		return obj.map((val) => deepClone(val))
	} else if (typeof obj === "object") {
		const res: any = {}
		for (const k in obj) {
			res[k] = deepClone(obj[k])
		}
		return res
	} else {
		return obj;
	}
}

export function clearObject(obj: any) {
	for (const key in obj) delete obj[key];
}

/**
 * 
 * @param n 
 * @param fn 
 * @param immed 
 * @returns 
 */
export const debounce = (n: number, fn: (...params: any[]) => any, immed = false) => {
	let timer: any = undefined;

	return function (this: any, ...args: any[]) {
		if (timer === undefined && immed) {
			fn.apply(this, args);
		}

		clearTimeout(timer);

		timer = setTimeout(() => fn.apply(this, args), n);

		return timer;
	}
};

export const throttle = (callee: any, timeout: any) => {
	let timer: any = null;

	return function perform(...args: any[]) {
		if (timer) return;

		timer = setTimeout(() => {
			callee(...args);

			clearTimeout(timer);

			timer = null;
		}, timeout);
	}
}

export const dataURItoBlob = (dataURI: string) => {
	const byteString = atob(dataURI.split(',')[1]);
	const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
	const ab = new ArrayBuffer(byteString.length);
	const ia = new Uint8Array(ab);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	return new Blob([ab], { type: mimeString });
}

export const srcToBlob = (src: string) => {
	return new Promise((resolve) => {
		const img = new Image;
		img.src = src;
		const c = document.createElement("canvas");
		const ctx: any = c.getContext("2d");
		img.onload = function (this: any) {
			c.width = this.naturalWidth;
			c.height = this.naturalHeight;
			ctx.drawImage(this, 0, 0);
			c.toBlob((blob) => resolve(blob), "image/jpeg", 0.75);
		};
	})
}

export const isObject = (item: any) => {
	return (item && typeof item === 'object' && !Array.isArray(item));
}

export const isArray = (item: any) => {
	return (item && typeof item === 'object' && Array.isArray(item));
}

export function isAsync(func: any) {
	return func.constructor && func.constructor.name === 'AsyncFunction';
}

export const deepMerge = (target: any, ...sources: any): any => {
	if (!sources.length) return target;

	const source = sources.shift();

	if (isObject(target) && isObject(source)) {
		for (const key in source) {
			if (isObject(source[key])) {
				if (!target[key]) Object.assign(target, { [key]: {} });

				deepMerge(target[key], source[key]);
			} else {
				Object.assign(target, { [key]: source[key] });
			}
		}
	}

	return deepMerge(target, ...sources);
}

export const b64toBlob = (b64Data: any, contentType = '', sliceSize = 512) => {
	const byteCharacters = atob(b64Data);

	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);
		const byteNumbers = new Array(slice.length);

		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);

		byteArrays.push(byteArray);
	}

	return new Blob(byteArrays, { type: contentType });
}

export function formatGuid(data: number[], format = "{3}{2}{1}{0}-{5}{4}-{7}{6}-{8}{9}-{10}{11}{12}{13}{14}{15}") {
	for (let i = 0; i < data.length; i++) {
		const re = new RegExp("\\{" + i + "\\}", "g");

		const dataStr = data[i].toString(16);

		format = format.replace(re, data[i] >= 16 ? dataStr : "0" + dataStr);
	}

	return format;
}

export function bufferToHash(buffer: any) {
	let hexString = '';

	buffer.forEach((value: number) => {
		hexString = hexString + dechex(value);
	});

	return formatGuid(md5(hexString).match(/[\da-f]{2}/gi).map((h: any) => parseInt(h, 16)));
}

/**
 * HEX строку в буфер
 * @param hex строка
 * @returns буфер
 */
export function hex2buffer(hex: any): any {
	return Buffer.from(hex.match(/[\da-f]{2}/gi).map((h: any) => parseInt(h, 16)))
}

export function textareaInsertText(textarea: any, text: string) {
	const start = textarea.selectionStart;
	//ищем последнее положение выделенного символа
	const end = textarea.selectionEnd;
	// текст до + вставка + текст после (если этот код не работает, значит у вас несколько id)
	const finText = textarea.value.substring(0, start) + text + textarea.value.substring(end);
	// подмена значения
	textarea.value = finText;
	// возвращаем фокус на элемент
	textarea.focus();
	// возвращаем курсор на место - учитываем выделили ли текст или просто курсор поставили
	textarea.selectionEnd = (start == end) ? (end + text.length) : end;
}

export function checkNumberEAN13(s: string) {
	let result = 0;
	let i = 1;

	for (let counter = s.length - 2; counter >= 0; counter--) {
		result = result + parseInt(s.charAt(counter)) * (1 + (2 * (i % 2)));

		i++;
	}

	return (10 - (result % 10)) % 10;
}

export function checkEAN13(s: string) {
	if (s.length == 13) {
		return checkNumberEAN13(s) == Number(s[12]);
	} else {
		return false;
	}
}

export function join(arr: any, separator = ' ', data: any = {}) {
	return arr
		.filter((el: any) => el)
		.map((el: any) => {
			if (typeof el == 'object') {
				const result = [];

				for (const key in el) {
					if (el[key] && data[key]) result.push(`${el[key]}: ${data[key]}`)
				}

				return result.join(separator);
			} else {
				return el;
			}
		})
		.join(separator);
}

/**
 * Растягивание строки до определенной длины
 * @param input строка
 * @param pad_length длина строки для выравнивания
 * @param pad_string cтрока заполнитель
 * @param pad_type тип выравнивания STR_PAD_LEFT, STR_PAD_RIGHT, STR_PAD_BOTH
 * @returns 
 */
export function strPad(input: string, pad_length: number, pad_string = ' ', pad_type = 'STR_PAD_RIGHT'): string {
	let half = '';
	let pad_to_go: number;

	const strPadRepeater = function (s: string, len: number) {
		let collect = '';

		while (collect.length < len) collect += s;
		collect = collect.substr(0, len);

		return collect;
	};

	if ((pad_to_go = pad_length - input.length) > 0) {
		if (pad_type == 'STR_PAD_LEFT') {
			input = strPadRepeater(pad_string, pad_to_go) + input;
		} else if (pad_type == 'STR_PAD_RIGHT') {
			input = input + strPadRepeater(pad_string, pad_to_go);
		} else if (pad_type == 'STR_PAD_BOTH') {
			half = strPadRepeater(pad_string, Math.ceil(pad_to_go / 2));
			input = half + input + half;
			input = input.substr(0, pad_length);
		}
	}

	return input;
}

export function adjustLAT(str: string) {
	const rus = 'КЕНХВАРОСМТ';
	const lat = 'KEHXBAPOCMT';

	let result = '';

	if (str) {
		for (let i = 0; i < str.length; i++) {
			const rusIndex = rus.indexOf(str[i].toLocaleUpperCase());

			result += rusIndex != -1 ? lat[rusIndex] : str[i].toLocaleUpperCase();
		}
	}

	return result;
}

export const firstLetterToUppercase = (value: string) => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

export const arr_en = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
export const arr_EN = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
export const arr_ru = ['а', 'б', 'в', 'г', 'д', 'е', 'ё', 'ж', 'з', 'и', 'й', 'к', 'л', 'м', 'н', 'о', 'п', 'р', 'с', 'т', 'у', 'ф', 'х', 'ц', 'ч', 'ш', 'щ', 'ь', 'ы', 'ъ', 'э', 'ю', 'я'];
export const arr_RU = ['А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ё', 'Ж', 'З', 'И', 'Й', 'К', 'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У', 'Ф', 'Х', 'Ц', 'Ч', 'Ш', 'Щ', 'Ь', 'Ы', 'Ъ', 'Э', 'Ю', 'Я'];

export const nameProcessing = (name: string) => {
	let result = '';

	if (name) {
		const _name = name.trim();

		let upper = true;

		let lastChar = '';

		for (let i = 0; i < _name.length; i++) {
			if (_name[i] == ' ') {
				if (lastChar != ' ') result += _name[i];
			} else {
				if (arr_ru.includes(_name[i]) || arr_RU.includes(_name[i]) || arr_en.includes(_name[i]) || arr_EN.includes(_name[i])) {
					result += lastChar == ' ' || upper ? _name[i].toLocaleUpperCase() : _name[i].toLocaleLowerCase();
					upper = false;
				} else {
					result += _name[i];
					upper = true;
				}
			}

			lastChar = _name[i];
		}
	}

	return result;
}

/**
 * ФИО в строку
 * Example: fullName('Фамилия', 'Имя','Отчество')
 * @param last_name Фамилия
 * @param first_name Имя
 * @param middle_name Отчество
 * @returns 
 */
export function fullName(last_name: string, first_name: string, middle_name = null) {
	return [last_name, first_name, middle_name].join(' ');
}

export function fio(name: any) {
	const result = [];

	if (name) {
		const arr: any = name.split(' ')

		if (arr.length > 0) result.push(arr[0]);
		if (arr.length > 1) result.push(` ${arr[1].substr(0, 1)}.`);
		if (arr.length > 2) result.push(`${arr[2].substr(0, 1)}.`);
	}

	return result.join('');
}

export function iof(name: any) {
	const result = [];

	if (name) {
		const arr: any = name.split(' ')

		if (arr.length > 1) result.push(`${arr[1].substr(0, 1)}.`);
		if (arr.length > 2) result.push(`${arr[2].substr(0, 1)}.`);
		if (arr.length > 0) result.push(arr[0]);
	}

	return result.join(' ');
}

export function translit(text: string, engToRus = false) {
	const
		rus = "щ   ш  ч  ц  ю  я  ё  ж  ъ  ы  э  а б в г д е з и й к л м н о п р с т у ф х ь".split(/ +/g),
		eng = "shh sh ch cz yu ya yo zh '' y' e' a b v g d e z i j k l m n o p r s t u f x '".split(/ +/g);

	for (let x = 0; x < rus.length; x++) {
		text = text.split(engToRus ? eng[x] : rus[x]).join(engToRus ? rus[x] : eng[x]);
		text = text.split(engToRus ? eng[x].toUpperCase() : rus[x].toUpperCase()).join(engToRus ? rus[x].toUpperCase() : eng[x].toUpperCase());
	}

	return text;
}

export function updateTextareaHeight(textarea: any) {
	if (textarea) {
		textarea.style['height'] = 'auto';

		if (textarea.scrollHeight > 0) {
			textarea.style['height'] = (textarea.scrollHeight + 2) + 'px';
		}
	}
}

export function checkPersonalNumber(num: string): boolean {
	if (num == '-') return true;

	return num.length === 14 && /^[A-Za-z0-9]{14}$/.test(num) &&
		[...num.toUpperCase()]
			.map((c) => (/[A-Z]/.test(c) ? c.charCodeAt(0) - 55 : +c))
			.slice(0, 13)
			.reduce((s, d, i) => s + d * [7, 3, 1, 7, 3, 1, 7, 3, 1, 7, 3, 1, 7][i], 0) % 10 === (/[A-Z]/.test(num[13]) ? num[13].charCodeAt(0) - 55 : +num[13]);
}

/**
 * Округление
 * @param num 1.125
 * @param decimalPlaces 2
 * @returns 1.13
 */
export function truncated(num: number, decimalPlaces: number) {
	const numPowerConverter = Math.pow(10, decimalPlaces);
	return ~~(num * numPowerConverter) / numPowerConverter;
}

/**
 * Сравнение двух чисел на равенство
 * @param x 0.1 + 0.2
 * @param y 0.3
 * @returns true
 */
export function epsEqu(x: number, y: number) {
	return Math.abs(x - y) < Number.EPSILON * Math.max(Math.abs(x), Math.abs(y));
}

export function blobSaveToFile(blob: any, fileName: string) {
	const link = document.createElement('a');

	link.download = fileName;

	link.href = URL.createObjectURL(blob);

	link.click();

	URL.revokeObjectURL(link.href);
}