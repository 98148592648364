<script>
import { defineComponent, h, onMounted, onUnmounted, ref, resolveComponent, computed } from 'vue';
import DBQuestionaryController from './controller';
import { genGUID } from '@/core/helpers/utils';

export default defineComponent({
	props: {
		form: {
			type: Object,
			default: null
		},
		field: {
			type: String,
			default: ''
		},
		label: {
			type: [Boolean, String],
			default: true
		},
		readonly: {
			type: Boolean,
			default: null
		},
		validation: {
			type: Boolean,
			default: true
		}
	},

	setup(props) {
		const DBEdit = resolveComponent('DBEdit');

		const controller = new DBQuestionaryController(props);

		if (!controller.structure) {
			console.error(`DBQuestionary: Не найдено поле(${controller.field.value})!`);

			return () => null
		}

		const validation = () => controller.validation();

		onMounted(() => {
			props.form.addValidation(validation);
		})

		onUnmounted(() => {
			props.form.delValidation(validation);
		})

		if (controller.structure.type == 'JSON') {
			const fields = controller.structure?.model?.fields;

			if (!fields) return null;

			const data = computed(() => controller.data[props.field]);

			const inputs = {};

			const uncheck = (items) => {
				for (const item in items) {
					for (const field in fields) {
						if (item == field) {
							if (fields[field]?.config?.field) {
								const type = controller.store.state.fields[fields[field].config.field].type;

								controller.data[fields[field].config.field] = type == 'BOOLEAN' ? false : null;
							} else {
								data.value[field] = fields[field].type == 'BOOLEAN' ? false : null;
							}

							uncheck(items[item].items);
						}
					}
				}
			}

			const createInputs = (items, parent = null) => {
				for (const nameField in fields) {
					const item = {
						[nameField]: {
							...fields[nameField],
							items: {}
						}
					};

					const config = fields[nameField].config;

					if (parent) {
						if (config?.parent) {
							if (config?.parent == parent) {
								Object.assign(items, item);
							} else {
								if (items[config.parent]) {
									createInputs(items[config.parent].items, config.parent);
								}
							}
						}
					} else {
						if (config?.parent) {
							if (items[config.parent]) {
								createInputs(items[config.parent].items, config.parent);
							}
						} else {
							Object.assign(items, item);
						}
					}
				}
			}

			createInputs(inputs);

			return () => {
				let row = 0;

				const elements = [];

				const render = (items) => {
					for (const fieldName in items) {
						const input = items[fieldName];

						const config = input.config;

						if (config?.visible === false) continue;

						const field = config?.field ? config.field : `${props.field}.${fieldName}`;

						if (config?.parent) {
							const _input = inputs[config.parent];

							if (_input?.config?.field) {
								if (_input.config.value) {
									if (controller.data[_input.config.field] != _input.config.value) continue;
								} else {
									if (!controller.data[_input.config.field]) continue;
								}
							} else {
								if (data?.value && !data.value[config.parent]) continue;
							}
						}

						if (config?.row) row = config.row;

						if (config?.chapter) {
							elements.push({
								el: h(
									'div',
									{ class: "col-auto" },
									h('label', { class: { 'font-weight-bold': true } }, config.chapter)
								),
								row
							})

							row++;
						}

						if (config?.description) {
							elements.push({
								el: h(
									'div',
									{ class: "col-auto" },
									h('label', {}, config.description)
								),
								row
							})

							row++;
						}

						if (config?.label) {
							elements.push({
								el: h(
									'div',
									{ class: "col-auto" },
									h('label', { style: 'font-weight: 300;', class: { 'mb-1': true, 'font-weight-bold': !!config?.labelBold } }, config.label)
								),
								row
							})
						}

						if (input.type == 'BOOLEAN') {
							const id = `id_${genGUID()}`;

							if (config.field && config.value) {
								const enums = input.type?.enum ? input.type.enum : controller.store.state.fields[field].type.enum;

								elements.push({
									el: h(
										'div', { class: 'col-auto' },
										h(
											'div',
											{ class: 'form-check form-check-inline mb-1' },
											[
												h(
													'input',
													{
														type: 'checkbox',
														class: 'form-check-input',
														id,
														checked: controller.data[field] == config.value,
														disabled: controller.readonly.value,
														onClick: (e) => {
															const checked = e.target.checked;
															if (checked) {
																for (const el of enums) {
																	if (el.id == config.value) {
																		controller.data[field] = el.id;
																		controller.data[`_${field}`] = el.name;

																		return;
																	}
																}
															}

															// uncheck(input.items);

															// console.log(input, config, field, checked, items);

															controller.data[field] = null;
															if (controller.data[`_${field}`]) delete controller.data[`_${field}`];
														},
														...(config?.props ? config.props : {})
													}
												),
												h('label', { class: 'form-check-label', for: id }, input.description)
											]
										)
									),
									row
								});
							} else {
								elements.push({
									el: h(
										'div',
										{ class: "col-auto", key: field },
										h(
											'div',
											{ class: 'form-check form-check-inline mb-1' },
											h(
												DBEdit,
												{
													form: props.form,
													field,
													onClick: (value) => {
														if (value) {
															const group = config.group;

															for (const field in fields) {
																if (fields[field]?.config?.group == group && field != fieldName) {
																	if (fields[field]?.config?.field) {
																		const type = controller.store.state.fields[fields[field].config.field].type;

																		controller.data[fields[field].config.field] = type == 'BOOLEAN' ? false : null;
																	} else {
																		data.value[field] = fields[field].type == 'BOOLEAN' ? false : null;
																	}

																	// uncheck(items[fieldName].items);
																	uncheck(items[field].items);
																}
															}
														} else {
															uncheck(input.items);
														}
													},
													...(config?.props ? config.props : {})
												}
											)
										)
									),
									row
								})
							}
						} else if (config?.checksBoxes) {
							const enums = input.type?.enum ? input.type?.enum : controller.store.state.fields[field].type.enum;

							for (const item of enums) {
								const id = `id_${genGUID()}`;

								elements.push({
									el: h(
										'div', { class: 'col-auto' },
										h(
											'div',
											{ class: 'form-check form-check-inline mb-1' },
											[
												h('input', {
													type: 'checkbox',
													class: 'form-check-input',
													id,
													disabled: controller.readonly.value,
													checked: (input.type?.enum ? data.value[fieldName] : controller.data[field]) == item.id,
													onClick: (e) => {
														if (input.type?.enum) {
															if (e.target.checked) {
																data.value[fieldName] = item.id;
																data.value[`_${fieldName}`] = item.name;
															} else {
																data.value[fieldName] = null;
																if (data.value[`_${fieldName}`]) delete data.value[`_${fieldName}`];
															}
														} else {
															if (e.target.checked) {
																controller.data[field] = item.id;
																controller.data[`_${field}`] = item.name;
															} else {
																controller.data[field] = null;
																if (controller.data[`_${field}`]) delete controller.data[`_${field}`];
															}
														}
													},
													...(config?.props ? config.props : {})
												}),
												h('label', { class: 'form-check-label', for: id }, item.name)
											]
										)
									),
									row
								});
							}
						} else {
							elements.push({
								el: h(
									'div',
									{ class: config?.fullWidth ? "col mb-1" : "col-auto mb-1", key: field },
									h(DBEdit, {
										form: props.form,
										field,
										label: false,
										raw: true,
										placeholder: input.description,
										style: config?.style ? config.style : {},
										...(config?.props ? config.props : {})
									})
								),
								row
							})
						}

						if (config?.mark) {
							elements.push({
								el: h(
									'div',
									{ class: "col-auto" },
									h('label', { style: 'font-weight: 300;', class: { 'mb-1': true } }, config.mark)
								),
								row
							})
						}

						if (Object.keys(input.items).length > 0) render(input.items);
					}
				}

				if (controller.label) {
					elements.push({
						el: h('div', { class: "col-auto" }, h('label', { class: "font-weight-bold", style: "margin-bottom: 0.25rem;" }, [controller.label])),
						row
					})
				}

				render(inputs);

				if (controller.verified.value && controller.feedback.value) {
					elements.push({
						el: h('div', { class: "invalid-feedback", style: 'margin: -0.5rem 0 0.5rem 0.3rem !important;' }, controller.feedback.value),
						row
					});
				}

				const _elements = Object.groupBy(elements, el => el.row);

				const result = [];

				for (const key in _elements) {
					result.push(h('div', { class: "form-row align-items-center" }, _elements[key].map(el => el.el)))
				}

				return result;
			}
		} else if (controller.structure.type == 'TEXT') {
			const config = controller.structure.config;

			return () => {
				const elements = [];

				if (typeof config?.props?.label == 'boolean' ? config.props.label : true) {
					elements.push(h(
						'label',
						{ class: "font-weight-bold" },
						controller.label
					))
				}

				elements.push(
					h(DBEdit, {
						form: props.form,
						field: props.field,
						label: false,
						raw: true,
						...(config?.props ? config.props : {})
					})
				);

				return h('div', { class: "form-group" }, elements);
			}
		} else {
			const config = controller.structure.config;

			return () => {
				const elements = [];

				if (controller.label) {
					elements.push(h(
						'div',
						{ class: "col-auto" },
						h('label', { class: "font-weight-bold mb-0" }, [controller.label])
					))
				}

				elements.push(h(
					'div',
					{ class: config?.fullWidth ? "col" : "col-auto" },
					h(DBEdit, {
						form: props.form,
						field: config?.field ? config.field : props.field,
						label: false,
						raw: true,
						... (config?.props ? config.props : {})
					})
				))

				return h('div', { class: "form-row align-items-center pb-2" }, elements);
			}
		}
	}
})
</script>
