<template>
	<div class="media flex-column flex-md-row" v-for="comment of comments" :key="comment.id">
		<div class="mr-md-3 mb-2 mb-md-0">
			<router-link class="font-weight-semibold" :to="`/user/${comment.author}`">
				<img :src="avatar(comment)" class="rounded-circle" width="36" height="36" alt />
			</router-link>
		</div>

		<div class="media-body">
			<div class="media-title">
				<router-link class="font-weight-semibold" :to="`/user/${comment.author}`">{{ comment.authorname }}</router-link>
				<span class="font-size-sm text-muted ml-sm-2 mb-2 mb-sm-0 d-block d-sm-inline-block">{{ time(comment) }}</span>
			</div>

			<p v-html="comment.message"></p>

			<ul class="list-inline font-size-sm mb-0">
				<li class="list-inline-item" v-if="likes">
					<Likes :owner="comment.id"></Likes>
				</li>

				<li class="list-inline-item" v-if="!readonly">
					<a href="#" @click.prevent="() => reply(comment)">Ответить</a>
				</li>

				<li class="list-inline-item" v-if="(availabeRole('admin') || comment.author == state.user.employee) && !readonly">
					<a href="#" @click.prevent="() => edit(comment)">Редактировать</a>
				</li>

				<li class="list-inline-item" v-if="(availabeRole('admin') || comment.author == state.user.employee) && !readonly">
					<a href="#" @click.prevent="() => deletion(comment)">Удалить</a>
				</li>
			</ul>

			<template v-if="comment.id == id && !readonly">
				<div class="mb-1">
					<Editor :owner="comment.id" :options="options" ref="editor"></Editor>
				</div>

				<div class="text-left">
					<button type="button" class="btn btn-action mr-1" @click="() => send(comment)">{{ replymode ? 'Добавить комментарий' : 'Сохранить' }}</button>
					<button type="button" class="btn btn-action mr-1" @click="() => cancel(comment)">Отмена</button>
				</div>
			</template>

			<CommentsItems :comments="comment.children" :likes="likes" :timeAgo="timeAgo"></CommentsItems>
		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2';
import dayjs from "dayjs"
import tinymce from 'tinymce';
import { ref, toRefs } from 'vue';
import { useStore } from 'vuex';

import Likes from '@/core/components/Likes';
import Editor from '@/core/components/Editor';
import { timeago } from '@/core/helpers/utils';
import { availabeRole } from '@/core/helpers/access';

import sendComment, { sendDataComment } from './sendComment';
import { commentDelete } from './api';

import { config } from "@/config";

export default {
	name: 'CommentsItems',

	emits: ['send', 'data'],

	components: {
		Editor,
		Likes
	},

	props: {
		comments: {
			type: Array,
			default: () => []
		},
		likes: {
			type: Boolean,
			default: false
		},
		readonly: {
			type: Boolean,
			default: false
		},
		timeAgo: {
			type: Boolean,
			default: true
		}
	},

	setup(props, { emit }) {
		const { state } = useStore();

		const options = {
			height: 200,
			toolbar: 'bold italic underline strikethrough | forecolor backcolor removeformat | image',
			quickbars_selection_toolbar: '',
			init_instance_callback: () => null
		};

		const comment = ref('');
		const id = ref(null);
		const replymode = ref(false);

		const { comments } = toRefs(props);

		const avatar = (comment) => `${config.server}/api/images/avatar/${comment.avatar}`;

		const send = async (item) => {
			const editor = tinymce.activeEditor;

			if (replymode.value) {
				const response = await sendComment(item.id);

				item.value = "";

				editor.setContent(item.value, { format: 'html' })

				if (!('children' in item)) item.children = [];

				item.children.push(response);

				id.value = null;
				replymode.value = false;

				emit('send', response);
			} else {
				const data = {
					id: item.id,
					owner: item.owner,
					message: editor.getContent({ format: 'html' }),
					files: item.files,
					author: item.author
				}

				await sendDataComment(data);

				Object.assign(item, data);

				id.value = null;

				replymode.value = false;
			}
		};

		const reply = (item) => {
			id.value = item.id;
			replymode.value = true;

			options.init_instance_callback = (editor) => editor.setContent('', { format: 'html' });
		}

		const edit = (item) => {
			id.value = item.id;
			replymode.value = false;

			options.init_instance_callback = (editor) => editor.setContent(item.message, { format: 'html' });
		}

		const cancel = () => {
			id.value = null;
			replymode.value = false;
		}

		const time = (item) => props.timeAgo ? timeago(item.updated_at) : dayjs(item.updated_at).format('DD.MM.YYYY hh:mm.ss');

		const deletion = (item) => {
			Swal.fire({
				title: 'Удалить комментарий?',
				showCancelButton: true,
				confirmButtonText: 'Да',
				cancelButtonText: 'Отмена'
			}).then(({ value }) => {
				if (value) {
					commentDelete(item.id).then(() => {
						for (const i in comments.value) {
							if (comments.value[i].id == item.id) comments.value.splice(i, 1);
						}

						emit('data', comments.value);
					})
				}
			})
		}

		return {
			timeago,
			avatar,
			send,
			cancel,
			reply,
			edit,
			deletion,
			options,
			comment,
			id,
			replymode,
			state,
			time,
			availabeRole
		}
	}

}
</script>

<style></style>