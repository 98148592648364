<template>
	<DBGrid :form="form" :field="field" :config="configFiles" :readonly="readonly" ref="files"></DBGrid>
</template>

<script lang="jsx">
import { onMounted, onUnmounted, h, ref, watch } from 'vue';

import bus from '@/core/bus';
import ws_agent from "@/core/ws_agent";
import WebCamera from '@/core/components/WebCamera/index.vue'
import { openPanel } from '@/core/layouts';
import { b64toBlob } from '@/core/helpers/utils';
import { availableScanner } from '@/core/equipment';

import { config } from "@/config";

export default {
	emits: ['count'],

	props: {
		form: {
			type: Object,
			default: () => ({})
		},
		field: {
			type: String,
			default: 'files'
		},
		readonly: {
			type: Boolean,
			default: false
		},
		config: {
			type: Object,
			default: () => ({})
		}
	},

	setup(props, { emit }) {
		const files = ref(null);

		const configFiles = Object.assign(
			{
				autoOpen: false,
				searchpanel: false,
				onCreate: (controller) => {
					watch(() => controller.store.data.rows, (value) => emit('count', value.length), { deep: true });

					controller.store.fetchData({ all: true }).then((res) => emit('count', res.length))

					setTimeout(() => {
						if (props.readonly) {
							controller.createPanelFun([]);
						} else {
							controller.createPanelFun([
								{
									edit: controller.access.update ? {} : null,
									add: controller.access.create ? {} : null,
									scan: availableScanner.value ? {
										icon: <i class="icon icon-file"></i>,
										caption: 'Сканировать',
										class: 'btn btn-copy',
										onClick: async () => onCreateScan()
									} : null,
									paste: {
										icon: <i class="icon icon-clipboard"></i>,
										caption: 'Вставить из буфера',
										class: 'btn btn-copy',
										onClick: async () => {
											try {
												const clipboardItems = await navigator.clipboard.read();

												for (const clipboardItem of clipboardItems) {
													for (const type of clipboardItem.types) {
														const blob = await clipboardItem.getType(type);

														if (type == 'image/png') {
															props.form.addBlob(blob, 'image.png');
														} else if (type == 'text/plain') {
															props.form.addBlob(blob, 'clipboard.txt');
														}
													}
												}
											} catch (err) {
												console.error(err.name, err.message);
											}
										}
									},
									webCamera: {
										icon: <i class="icon icon-speaker"></i>,
										caption: 'Камера',
										class: 'btn btn-copy',
										onClick: () => {
											const panel = openPanel({
												width: '800px',
												modal: true,
												caption: "Камера",
												onCreate: (panelModal) => ({
													component: h(
														WebCamera,
														{
															panel: panelModal
														}
													),
													buttons: [
														{
															photo: {
																class: "btn btn-action",
																caption: 'Снимок',
																onClick: () => {
																	const blob = panelModal.ref.takeSnapshot();

																	props.form.addBlob(blob, 'image.jpg');

																	panelModal.close();
																}
															}
														}
													]
												}),
												onClose: () => panel.ref.closeCamera()
											})
										}
									},
									delete: controller.access.delete ? {} : null
								}
							])
						}
					}, 0)
				},
				cellClick: {
					name: (data) => {
						const files = props.form.store.files;

						const href = files[data['id']] ? URL.createObjectURL(files[data['id']]) : `${config.server}/api/files/inline/${data['id']}`;

						const link = document.createElement('a');
						link.setAttribute('href', href);
						link.setAttribute('download', data['name']);
						link.setAttribute('target', '_blank');

						link.click();
						link.remove();
					}
				}
			},
			props.config
		)

		const onCreateScan = () => ws_agent.send({ 'scan': true });

		const onWsLocalData = (wsData) => {
			if (wsData.file) {
				const { data, name, type } = wsData.file;

				const file = new File([b64toBlob(data)], name, { type });

				props.form.addBlob(file, file.name);
			}
		}

		onMounted(() => {
			bus.$on('ws-local-data', onWsLocalData);
		});

		onUnmounted(() => bus.$off('ws-local-data', onWsLocalData));

		return {
			files,
			configFiles
		}
	}
}
</script>

<style></style>