import ReconnectingWebSocket from 'reconnecting-websocket';

import Bus from '@/core/bus';
import stateStore from '@/core/store/index';

import { config } from "@/config";
import { hex2buffer } from './helpers/utils';

//Использовать Bus
class WSLocalClient {
	private ws: any;

	constructor() {
		this.ws = new ReconnectingWebSocket(
			config.ws_local,
			[],
			{
				connectionTimeout: 10000,
				maxRetries: 1,
				startClosed: true
			}
		);

		this.ws.onopen = () => {
			stateStore.dispatch('onlineAgent', true);

			const equipment = stateStore.state.equipment.filter((el: any) => [1, 2, 3, 4].includes(el.type));

			if (equipment.length > 0) this.send({ equipment });
		};

		this.ws.onmessage = async (message: any) => {
			const json = JSON.parse(message.data);

			// console.log(json);

			Bus.$emit('ws-local-data', json);

			if (json['barcode']) {
				const buffer = hex2buffer(json.barcode).filter((value: number) => value > 31);

				Bus.$emit('ws-barcode', buffer.toString());
			}

			if (json['equipment']) {
				for (const item of json['equipment']) {

					for (const i in stateStore.state.equipment) {
						const equipment: any = stateStore.state.equipment[i];

						if (item['id'] == equipment['id']) equipment.active = !!item['active'];
					}
				}
			}
		}

		this.ws.onclose = () => {
			stateStore.dispatch('onlineAgent', false);
		}
	}

	send(message = {}) {
		if (!stateStore.state.onlineAgent) return false;

		this.ws.send(JSON.stringify(message));

		return true;
	}

	open() {
		this.ws.reconnect();
	}
}

export default new WSLocalClient;
