import WS from '@/core/wsclient';
import bus from '@/core/bus';
import { toastSuccess } from '@/core/helpers/toastify';
import { getMenu } from "@/core/api/profile";
import { messageDelete, messageRead } from '@/core/api/messages';
import { logout, proceedToken, status } from '@/core/api/auth';

import router from "@/router";
import { config } from '@/config';

export default {
	async login({ commit }: { commit: any }, { token, user }: { token: any, user: any }) {
		if (user.exp) {
			const date = new Date;

			user.exp = date.getTime() + user.exp * 1000;
		}

		commit('token', token);
		commit('timeout', 0);
		commit('user', user);

		WS.send({ event: 'login', data: token });

		commit('menu', await getMenu(config.menu));

		await router.push('/');

		bus.$emit('login', {});
	},

	async status({ commit, state }: { commit: any, state: any }) {
		const response: any = await status();

		if (response.exp) {
			const date = new Date;

			response.exp = date.getTime() + response.exp * 1000;
		}

		if (!response.id && state.token) commit('token', null);

		commit('user', response);

		WS.send({ event: 'login', data: state.token });

		commit('menu', await getMenu(config.menu));

		return response;
	},

	async logout({ commit }: { commit: any }) {
		const response: any = await logout();

		commit('token', null);
		commit('timeout', 0);

		await router.push('/');

		commit('user', response);

		WS.send({ event: 'logout' });

		commit('menu', await getMenu(config.menu));

		bus.$emit('logout', {});
	},

	async proceedToken({ commit, state }: { commit: any, state: any }) {
		const response: any = await proceedToken();
		if (response.token) {
			if (response.exp) {
				const date = new Date;

				response.exp = date.getTime() + response.exp * 1000;
			}

			commit('timeout', 0);
			commit('token', response.token);
			commit('user', Object.assign(state.user, { exp: response.exp }));
		}
	},

	async messagesRead({ commit }: { commit: any }) {
		const response: any = await messageRead();

		commit('messages', response);
	},

	async messagesDelete({ state }: { state: any }, id: any) {
		for (const i in state.messages) {
			if (state.messages[i].id == id) {
				await messageDelete(id);

				state.messages.splice(i, 1);

				break;
			}
		}
	},

	newMessage({ state }: { state: any }, message: any) {
		toastSuccess(message.message);

		state.messages.unshift(message);
	},

	online({ commit }: { commit: any }, value: any) {
		commit('online', value);
	},

	onlineAgent({ commit }: { commit: any }, value: any) {
		commit('onlineAgent', value);
	},

	equipment(commit: any, value: any) {
		commit('equipment', value);
	},

	mute({ commit, state }: { commit: any, state: any }) {
		commit('mute', !state.mute);
	}
}
