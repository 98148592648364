import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("a", {
      href: "#",
      onClick: _cache[0] || (_cache[0] = _withModifiers(() => _ctx.onLike(), ["prevent"]))
    }, [
      _createElementVNode("i", {
        class: _normalizeClass(["icon icon-thumbsup", { 'text-success': _ctx.likes.userlike }])
      }, null, 2)
    ]),
    _createTextVNode(" " + _toDisplayString(_ctx.likes.rating) + " ", 1),
    _createElementVNode("a", {
      href: "#",
      onClick: _cache[1] || (_cache[1] = _withModifiers(() => _ctx.onDislike(), ["prevent"]))
    }, [
      _createElementVNode("i", {
        class: _normalizeClass(["icon icon-thumbsdown", { 'text-danger': _ctx.likes.userdislike }])
      }, null, 2)
    ])
  ], 64))
}