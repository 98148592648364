import { computed, h, nextTick, reactive, ref } from "vue";

import Select from '@/core/components/Select/index.vue';
import DBStoreRecord from "@/core/db_store_record";
import { list } from "@/core/api/templates";
import { openPanel } from "@/core/layouts";
import { getModel, editRecId, select as selectForm } from "@/core/db";
import { genGUID, isAsync, textareaInsertText } from "@/core/helpers/utils";

export default class DBEditController {
	public el: any;
	public dropDown: any;
	public store: DBStoreRecord;
	public data: any;
	public form: any;

	public structure: any;
	public structureField: any;
	public field: any;

	public label: string;
	public labelId: string;
	public readonly: any;
	public access: boolean;

	public required: any;
	public verified: any;
	public valid: any;
	public feedback: any;
	public message: any;

	public typeahead: any;
	public select: any;
	public selectMode: any;
	public multiSelect: any;
	public msData: any;

	public showPopup: any;
	public config: any;

	public menuShow: any;
	public menuItems: any;

	constructor(public props: any, public searchText: any) {
		this.el = ref(null);
		this.dropDown = ref(null);

		this.verified = ref<boolean>(false);
		this.valid = ref<boolean>(false);
		this.feedback = ref<string>('');
		this.message = ref<string>('');
		this.required = ref<boolean>(false);

		this.form = props.form;
		this.store = props.form.store;
		this.data = this.store.data;

		this.access = false;
		this.label = '';
		this.labelId = '';

		this.menuShow = ref<boolean>(false);
		this.menuItems = ref([]);
		this.msData = reactive({});

		this.showPopup = ref<boolean>(false);

		this.field = ref(this.props.field);

		this.labelId = this.props.id ? this.props.id : `${this.props.field}_` + genGUID();

		this.init();

		this.readonly = computed(
			() => {
				if (this.access) {
					return typeof props.readonly == 'boolean'
						? props.readonly
						: props.form.store.state.readonly || props.form.readonly.value || this.field.value == props.form.store.model.key || this.structure.sql
				} else {
					return true;
				}
			}
		)
	}

	getFieldModel(fields: any, structure: any): any {
		const field: any = fields.shift();

		if (fields.length > 0) {
			return this.getFieldModel(fields, structure.fields[field].model);
		} else {
			return structure.fields[field];
		}
	}

	get valueField() {
		return this.store.getValue(this.props.field);
	}

	get value() {
		return this.store.getValue(this.field.value);
	}

	set value(value: any) {
		this.store.setValue(this.field.value, value);

		this.required.value = this.isRequired() && !value;
	}

	getData(fields: any, data: any): any {
		const field: any = fields.shift();

		if (fields.length > 0) {
			if (!data[field]) data[field] = {};

			return this.getData(fields, data[field]);
		} else {
			return data;
		}
	}

	get notionValue() {
		const fields = this.field.value.split('.');

		const field = fields[fields.length - 1];

		const data = this.getData(fields, this.data);

		return data[`_${field}`];
	}

	get notion() {
		if (this.props.multiSelect) {
			const notion = this.structure?.config?.notion;
			if (notion && typeof notion == 'function') {
				return notion(this.msData, this);
			} else {
				return Object.keys(this.msData).map((el) => this.msData[el]).join(', ');
			}
		} else {
			return this.notionValue;
		}
	}

	set notion(value: any) {
		const fields = this.field.value.split('.');

		const field = fields[fields.length - 1];

		const data = this.getData(fields, this.data);

		data[`_${field}`] = value;
	}

	async addReference() {
		if (typeof this.structure.type == 'object' && this.structure.type.reference) {
			const store = new DBStoreRecord(this.structure.type.reference);

			const data = await store.newRecord(this.structure.config.edit, { [store.model.notion]: this.searchText.value });

			if (data) {
				this.setData(data);

				this.setFocusInput();

				this.verified.value && this.validation();
			}
		}
	}

	onBlur() {
		if (typeof this.structure.type == 'object') {
			if (this.structure.type.reference && this.typeahead) {
				if (!this.value && this.notion) {
					delete this.data[`_${this.field.value}`];
				}
			}
		} else if (typeof this.structure.type == 'string') {
			switch (this.structure.type) {
				case 'DECIMAL': {
					const precision = this.props?.precision || this.config?.precision;

					this.value = this.value ? (precision ? Number(this.value).toFixed(precision) : Number(this.value)) : null;
				}
					break;

				case 'SMALLINT':
				case 'INTEGER':
				case 'BIGINT': {
					if (this.props.min != null && this.value < this.props.min) {
						this.value = this.props.min;
					}

					if (this.props.max != null && this.value > this.props.max) {
						this.value = this.props.max;
					}
				}

					break;

				default:
					break;
			}
		}

		this.config?.onBlur && this.config.onBlur(this.value, this.data, this);

		return this.verified.value && !this.valid.value && this.validation();
	}

	onFocusOpenPopup() {
		if (this.structure?.type?.reference && this.props.selectMode && !this.value) {
			this.showPopup.value = true;
		}
	}

	openPopup() {
		if (this.readonly.value) return;

		if (this.showPopup.value) {
			this.closePopup();
		} else {
			this.showPopup.value = true;
		}
	}

	/**
	 * Закрытие выпадающего списка
	 */
	closePopup() {
		this.showPopup.value = false;
	}

	/**
	 * Метод валидации
	 * @returns 
	 */
	validation(noMessage = false) {
		if (this.props.validation && this.structure.validation) {
			for (const i in this.structure.validation) {
				const result = this.structure.validation[i](this.store.getValue(this.field.value, false), this.data);

				if (result !== false) {
					if (!noMessage) {
						this.verified.value = true;
						this.valid.value = false;
						this.feedback.value = result;
					}

					return result;
				}
			}
		}

		if (!noMessage) {
			this.verified.value = false;
			this.valid.value = true;
			this.feedback.value = '';
		}

		return false;
	}

	/**
	 * Событие "Выбор из справочника"
	 */
	async selectReference() {
		if (!this.readonly.value) {
			if (typeof this.structure.type == 'object' && this.structure.type.reference) {
				const _config = this.config?.select ? this.config.select : {};

				if (this.config?.options) {
					Object.assign(_config, this.config.options);

					//Нужно для табличных частей, фильтру передать текущие данные строки
					if (this.data) Object.assign(_config, { data: this.data });
				}

				if (this.props.group) Object.assign(_config, { group: true });

				const reference = this.structure.type.reference;
				const schema = getModel(reference);

				let owner = null;

				if (schema.ownerField) {
					if (this.structure.depends) {
						owner = this.store.getValue(this.structure.depends);
					} else if (this.config?.owner) {
						owner = this.config.owner;
					}

					if (!owner) return;
				}

				if (schema?.access?.read) {
					const selectData = await selectForm(reference, this.value, owner, _config);

					if (selectData && typeof selectData == 'object') this.setSelectData(selectData);

					nextTick(() => this.setFocusInput());
				}
			}
		}
	}

	setSelectData(data: any) {
		const reference = this.structure.type.reference;
		const schema = getModel(reference);

		if (data && data[schema.key] != this.value) {
			this.value = data[schema.key];
			this.notion = data[schema.notion];

			this.store.clearDepends(this.field.value);

			if (this.config?.onSelect) this.config.onSelect(data, this.store);

			this.verified.value && !this.valid.value && this.validation();
		}
	}

	/**
	 * Открытие формы редактирования справочника
	 */
	async openReference() {
		if (typeof this.structure.type == 'object' && this.structure.type.reference && this.value != '00000000-0000-0000-0000-000000000000') {
			const schema = getModel(this.structure.type.reference);
			if (schema.access?.read) {
				let owner = null;

				const config = this.config?.edit ? this.config.edit : {}

				if (this.props.group) Object.assign(config, { group: true });

				if (schema.ownerField) {
					if (this.structure.depends) {
						owner = this.store.getValue(this.structure.depends);
					} else if (this.config?.owner) {
						owner = this.config.owner;
					}

					if (!owner) return;
				}

				await editRecId(
					this.structure.type.reference,
					this.value,
					owner,
					config
				);

				nextTick(() => this.setFocusInput());
			}
		}
	}

	setFocusInput() {
		const input = this.el.value;

		if (input) ('$el' in input ? input.$el : input).focus();
	}

	init() {
		this.field.value = this.props.field;

		const field = this.field;

		this.structure = this.getFieldModel(field.value.split('.'), this.store.state);

		if (this.structure) {
			this.label = typeof this.props.label == 'boolean' ? this.structure.description : this.props.label;

			this.structureField = this.structure.type?.fields ? this.getFieldModel(field.value.split('.'), this.store.state) : null;

			if (this.structureField) {
				if (this.value) {
					field.value = this.value;

					this.structure = this.getFieldModel(field.value.split('.'), this.store.state);
				}

				if (typeof this.structureField?.access == 'boolean') {
					this.access = this.structureField.access;
				} else {
					this.access = this.structureField?.access ? this.store.availabeRole(this.structureField.access, field.value, this.structureField, this.data) : true;
				}
			} else {
				if (typeof this.structure.access == 'boolean') {
					this.access = this.structure.access;
				} else {
					this.access = this.structure.access ? this.store.availabeRole(this.structure.access, field.value, this.structure, this.data) : true;
				}
			}

			this.config = this.structure?.config ? this.structure.config : {};

			this.typeahead = this.config?.typeahead;
			this.select = this.config?.select;

			this.selectMode = this.props.selectMode || (this.structure.type?.enum && !this.config?.radio);

			this.multiSelect = this.props.multiSelect;

			if (this.multiSelect && this.value) this.msData[this.value] = this.notionValue;
		}

		setTimeout(() => this.showPopup.value = false, 0);
	}

	clearValue() {
		this.field.value = this.props.field;

		if (this.structureField) {
			for (const field of this.structureField.type.fields) {
				this.store.clearField(field.field);
			}

			const fields = this.field.value.split('.');

			const field = fields[fields.length - 1];

			const data = this.getData(fields, this.data);

			data[field] = null;
		} else {
			this.store.clearField(this.field.value);
		}

		this.init();

		if (this.config?.onSelect) this.config?.onSelect(null, this.form.store);
		if (this.config?.onClear) this.config?.onClear(this.form.store);

		this.setFocusInput();

		if (this.multiSelect) {
			Object.keys(this.msData).forEach((key: any) => delete this.msData[key]);

			if (this.config?.onMultiSelect) this.config.onMultiSelect(this.msData, this);
		}
	}

	selectType() {
		openPanel({
			modal: true,
			caption: this.structureField?.config?.description ? this.structureField.config.description : 'Выбор типа поля',
			onCreate: (panelModal: any) => {
				return {
					component: h(Select, {
						field: 'description',
						items: this.structureField.type.fields,
						onSelect: (data: any) => {
							this.value = data.field;

							this.init();

							panelModal.close();

							if (this.structure.type.reference) {
								if (!this.props.selectMode) this.selectReference();
							} else {
								nextTick(() => this.setFocusInput());
							}
						}
					}),
					buttons: [
						{
							select: {
								class: "btn btn-action",
								caption: 'Выбрать',
								onClick: async () => panelModal.ref.select()
							}
						}
					]
				}
			}
		})
	}

	isRequired() {
		return this.props.validation && this.structure.validation && Object.keys(this.structure.validation).includes('isRequired');
	}

	/**
	 * Установка значения
	 * @param value 
	 */
	setValue(value: any) {
		this.value = value;

		this.validation();
	}

	async openMenu(menuItems: any) {
		setTimeout(() => this.menuShow.value = true, 0);

		const items: any = this.menuItems.value;

		items.splice(0, items.length);

		const _menuItems = typeof menuItems == 'function' ? (isAsync(menuItems) ? await menuItems(this) : menuItems(items)) : menuItems;

		_menuItems.forEach((el: any) => items.push(el))
	}

	/**
	 * Открытие меню textarea
	 * @param event 
	 */
	async onOpenMenuTextarea() {
		const items = await this.createTemplatesMenu();

		this.openMenu(items);
	}

	setTemplateValue(value: any) {
		if (typeof this.structure.type == 'string') {
			if (this.config?.onTemplate) this.config.onTemplate(value, this.store);

			if (this.structure.type == 'TEXT') {
				textareaInsertText(this.el.value, value.template);

				this.setValue(this.el.value.value);

				nextTick(() => this.updateTextareaHeight());
			} else {
				const result = [];

				if (this.value) result.push(this.value);
				if (value.template) result.push(value.template);

				this.setValue(result.join(' '));
			}
		}
	}

	async templates() {
		const result = await selectForm(
			'templates',
			'',
			null,
			{
				default: {
					document_type: this.store.model.id,
					field: this.field.value,
					template: this.el.value.value
				},
				filters: [
					{
						where: '"templates"."document_type" = :document_type and "templates"."field" = :field',
						params: {
							field: this.field.value,
							document_type: this.store.model.id
						}
					}
				]
			}
		)

		if (result && typeof result == 'object') this.setTemplateValue(result);
	}

	async createTemplatesMenu() {
		const result: any = [
			{
				icon: <i class="icon icon-file"> </i>,
				caption: 'Шаблоны',
				onClick: async () => await this.templates()
			}
		];

		const items: any = [];

		const templates = this.structure?.config?.templates;
		if (templates) {
			const itemsTemplates = typeof templates == 'function' ? templates(this) : templates;
			for (const item of itemsTemplates) {
				const template: any = {
					icon: <i class="icon icon-file"> </i>,
					caption: item.template,
					title: item.template,
					onClick: async () => this.setTemplateValue(item)
				}

				if (item.items) {
					template.items = item.items.map((el: any) => ({
						icon: <i class="icon icon-file"> </i>,
						caption: el.template,
						title: el.template,
						onClick: async () => this.setTemplateValue(el)
					}))
				}

				items.push(template);
			}
		}

		const response: any = await list(this.store.model.id, this.field.value);

		if (response.length > 0) {
			for (const template of response) {
				if (template.items.length > 0) {
					items.push({
						icon: <i class="icon icon-file"> </i>,
						caption: template.template,
						title: template.template,
						items: template.items.map((el: any) => ({
							icon: <i class="icon icon-file"> </i>,
							caption: el.template,
							title: el.template,
							onClick: async () => this.setTemplateValue(el)
						}))
					})
				} else {
					items.push({
						icon: <i class="icon icon-file"> </i>,
						caption: template.template,
						title: template.template,
						onClick: async () => this.setTemplateValue(template)
					})
				}
			}
		}

		if (items.length > 0) {
			result.push({ caption: '-' });

			items.forEach((item: any) => result.push(item));
		}

		return result;
	}

	updateTextareaHeight() {
		if (typeof this.structure.type === 'string') {
			if (this.structure.type == 'TEXT' && this.props.rows > 0) {
				const textarea = this.el.value;

				if (textarea) {
					textarea.style['height'] = 'auto';
					if (textarea.scrollHeight > 0) {
						textarea.style['height'] = (textarea.scrollHeight + 2) + 'px';
					}
				}
			}
		}
	}

	triggers() {
		const result = [];

		if (typeof this.structure.type == 'object') {
			if (this.structure.type.reference) {
				if (this.readonly.value) {
					const schema = getModel(this.structure.type.reference);
					if (schema?.access?.read && this.props.view) {
						result.push({
							text: <i class="icon icon-eye"></i>,
							title: 'Просмотр (F2)',
							onClick: async () => await this.openReference()
						});
					}
				} else {
					if (this.props.selectMode || this.props.multiSelect) {
						result.push({
							text: <i class="icon icon-x"></i>,
							title: 'Очистить (F8)',
							onClick: () => this.clearValue()
						});
					} else {
						result.push({
							text: <i class="icon icon-more-horizontal"></i>,
							title: 'Выбрать (F4)',
							onClick: async () => await this.selectReference()
						});

						const schema = getModel(this.structure.type.reference);
						if (schema?.access?.update) {
							result.push({
								text: <i class="icon icon-edit"></i>,
								title: 'Изменить (F2)',
								onClick: async () => await this.openReference()
							});
						} else {
							if (this.props.view) {
								result.push({
									text: <i class="icon icon-eye"></i>,
									title: 'Просмотр (F2)',
									onClick: async () => await this.openReference()
								});
							}
						}

						result.push({
							text: <i class="icon icon-x"></i>,
							title: 'Очистить (F8)',
							onClick: () => this.clearValue()
						});
					}
				}
			} else if (this.structure.type.enum) {
				if (this.props.clear && !this.readonly.value && !this.props.radio) {
					result.push({
						text: <i class="icon icon-x"></i>,
						title: 'Очистить (F8)',
						onClick: () => this.clearValue()
					});
				}
			} else if (!this.readonly.value && this.structure.type.fields) {
				result.push({
					text: <i class="icon icon-more-horizontal"></i>,
					title: 'Выбор типа',
					onClick: () => this.selectType()
				});
			}
		} else if (typeof this.structure.type == 'string') {
			if (this.props.clear && !this.readonly.value && this.structure.type != 'BOOLEAN') {
				result.push({
					text: <i class="icon icon-x"></i>,
					title: 'Очистить (F8)',
					onClick: () => this.clearValue()
				});
			}
		}

		if (this.structure?.config?.triggers) {
			const triggers = typeof this.structure.config.triggers == 'function' ? this.structure.config.triggers(this) : this.structure.config.triggers;

			if (triggers.length > 0) triggers.forEach((el: any) => { result.push(el) })
		}

		return result;
	}

	/**
	 * Установка значения
	 * @param data 
	 */
	setData(data: any) {
		if (this.typeahead) {
			const reference = this.structure.type?.reference;

			if (reference) {
				const schema = getModel(reference);

				this.value = data[schema.key];
				this.notion = data[schema.notion] ? data[schema.notion] : data[schema.key];
			} else {
				this.value = data[this.props.textField];
			}
		} else if (this.multiSelect) {
			if (data.id in this.msData) {
				delete this.msData[data.id];
			} else {
				this.msData[data.id] = data.name;
			}

			if (this.config?.onMultiSelect) this.config.onMultiSelect(this.msData, this);
		} else {
			this.value = data[this.props.valueField];
			this.notion = data[this.props.textField];

			if (this.structure.type?.enum) {
				const sel = this
					.store
					.getEnum(this.store.data, this.structure)
					.find((el: any) => el[this.props.valueField] == this.value);

				if (sel) Object.assign(data, sel);
			}
		}

		if (this.config?.onSelect) this.config.onSelect(data, this.store);

		this.store.clearDepends(this.field.value);

		this.validation();
	}
}
